<template>
  <ui-component-modal
    :modalTitle="'Edit option base settings'"
    :disableSaveButton="mOption.Name.length < 2"
    :onClickCancel="onClickCancel"
    :onClickSave="saveOptionBase"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template v-slot:content>
      <table class="table is-fullwidth">
        <tbody>
          <tr>
            <td>Name</td>
            <td>
              <ui-base-input-field
                v-model="mOption.Name"
                :error="nameErrors"
                :required="true"
                :showLabel="false"
                fieldType="text"
                @keyup.enter="saveOptionName"
                @input="$v.mOption.Name.$touch()"
                @blur="$v.mOption.Name.$touch()"
              />
            </td>
          </tr>
          <tr>
            <td>Max amount</td>
            <td>
              <div
                class="tabs is-toggle is-inline-flex is-small is-pulled-left"
              >
                <ul>
                  <li
                    :class="{
                      'is-active': mOption.MaxAmount === 0,
                    }"
                  >
                    <a @click="mOption.MaxAmount = 0">
                      <span>Off</span>
                    </a>
                  </li>
                  <li
                    :class="{
                      'is-active': mOption.MaxAmount > 0,
                    }"
                  >
                    <a @click="mOption.MaxAmount = 1">
                      <span>On</span>
                    </a>
                  </li>
                </ul>
              </div>

              <div v-if="mOption.MaxAmount > 0">
                <input type="text" v-model="mOption.MaxAmount" class="input" />
              </div>
            </td>
          </tr>
          <tr>
            <td>Minimum duration</td>
            <td>
              <div
                class="tabs is-toggle is-inline-flex is-small is-pulled-left"
              >
                <ul>
                  <li
                    :class="{
                      'is-active': mOption.MinimumReservationDuration === 0,
                    }"
                  >
                    <a @click="mOption.MinimumReservationDuration = 0">
                      <span>Off</span>
                    </a>
                  </li>
                  <li
                    :class="{
                      'is-active': mOption.MinimumReservationDuration > 0,
                    }"
                  >
                    <a @click="mOption.MinimumReservationDuration = 60">
                      <span>On</span>
                    </a>
                  </li>
                </ul>
              </div>

              <div
                v-if="mOption.MinimumReservationDuration > 0"
                class="has-padding-left"
              >
                <div class="select">
                  <select v-model="mOption.MinimumReservationDuration">
                    <option
                      v-for="(hour, index) in hours"
                      :key="index"
                      :value="hour"
                    >
                      {{ hour / 60 }}
                    </option>
                  </select>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>Maximum duration</td>
            <td>
              <div
                class="tabs is-toggle is-inline-flex is-small is-pulled-left"
              >
                <ul>
                  <li
                    :class="{
                      'is-active': mOption.MaximumReservationDuration === 0,
                    }"
                  >
                    <a @click="mOption.MaximumReservationDuration = 0">
                      <span>Off</span>
                    </a>
                  </li>
                  <li
                    :class="{
                      'is-active': mOption.MaximumReservationDuration > 0,
                    }"
                  >
                    <a @click="mOption.MaximumReservationDuration = 60">
                      <span>On</span>
                    </a>
                  </li>
                </ul>
              </div>

              <div
                v-if="mOption.MaximumReservationDuration > 0"
                class="has-padding-left"
              >
                <div class="select">
                  <select v-model="mOption.MaximumReservationDuration">
                    <option
                      v-for="(hour, index) in hours"
                      :key="index"
                      :value="hour"
                    >
                      {{ hour / 60 }}
                    </option>
                  </select>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>Minimum participants</td>
            <td>
              <div
                class="tabs is-toggle is-inline-flex is-small is-pulled-left"
              >
                <ul>
                  <li
                    :class="{
                      'is-active': mOption.MinimumParticipants === 0,
                    }"
                  >
                    <a @click="mOption.MinimumParticipants = 0">
                      <span>Off</span>
                    </a>
                  </li>
                  <li
                    :class="{
                      'is-active': mOption.MinimumParticipants > 0,
                    }"
                  >
                    <a @click="mOption.MinimumParticipants = 1">
                      <span>On</span>
                    </a>
                  </li>
                </ul>
              </div>

              <div v-if="mOption.MinimumParticipants > 0">
                <input
                  type="text"
                  v-model="mOption.MinimumParticipants"
                  class="input"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td>Maximum participants</td>
            <td>
              <div
                class="tabs is-toggle is-inline-flex is-small is-pulled-left"
              >
                <ul>
                  <li
                    :class="{
                      'is-active': mOption.MaximumParticipants === 0,
                    }"
                  >
                    <a @click="mOption.MaximumParticipants = 0">
                      <span>Off</span>
                    </a>
                  </li>
                  <li
                    :class="{
                      'is-active': mOption.MaximumParticipants > 0,
                    }"
                  >
                    <a @click="mOption.MaximumParticipants = 1">
                      <span>On</span>
                    </a>
                  </li>
                </ul>
              </div>

              <div v-if="mOption.MaximumParticipants > 0">
                <input
                  type="text"
                  v-model="mOption.MaximumParticipants"
                  class="input"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import optionProvider from '@/providers/option'

import { required, minLength } from 'vuelidate/lib/validators'
const touchMap = new WeakMap()

export default {
  components: {},

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      mOption: null,
      hours: [60, 120, 180, 240, 300, 360, 420, 480, 540, 600, 660, 720],
    }
  },

  /**
   * Form validation rules
   */
  validations() {
    let output = {
      Name: {
        required,
        minLength: minLength(2),
      },
    }
    return {
      mOption: output,
    }
  },

  computed: {
    ...mapState('locationStore', ['locationState']),
    ...mapState('optionStore', ['option']),

    nameErrors() {
      const errors = []
      if (!this.$v.mOption.Name.$dirty) return errors
      !this.$v.mOption.Name.required &&
        errors.push(this.$t('Form.InputErrors.Required'))
      !this.$v.mOption.Name.minLength &&
        errors.push(this.$t('Form.InputErrors.minLength', { minLength: 2 }))

      return errors
    },
  },

  created() {
    this.mOption = JSON.parse(JSON.stringify(this.option))
  },

  methods: {
    ...mapMutations('optionStore', ['setOption']),

    saveOptionBase() {
      let self = this

      let criteria = {
        Id: this.mOption.Id,
        LocationId: self.locationState !== null ? self.locationState.Id : 0,
        Name: this.mOption.Name,
        MaxAmount: this.mOption.MaxAmount,
        MinimumReservationDuration: this.mOption.MinimumReservationDuration,
        MaximumReservationDuration: this.mOption.MaximumReservationDuration,
        MinimumParticipants: this.mOption.MinimumParticipants,
        MaximumParticipants: this.mOption.MaximumParticipants,
      }

      if (!self.isSaving) {
        self.isSaving = true
        this.$v.$touch()
        if (this.$v.$invalid) {
          // 'ERROR'
          self.isSaving = false
        } else {
          optionProvider.methods
            .saveOptionBase(self.mOption.Id, criteria)
            .then((response) => {
              self.setOption(response.data)

              // Send message to display toast on screen
              EventBus.$emit('showToast', {
                type: 'info',
                message: 'Option updated',
              })

              self.onClickCancel()
            })
            .catch((e) => {
              self.isSavingError = true
            })
            .finally(() => {
              self.isSaving = false
            })
        }
      }
    },
  },
}
</script>
