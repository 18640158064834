var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ui-component-modal',{attrs:{"modalTitle":'Edit option base settings',"disableSaveButton":_vm.mOption.Name.length < 2,"onClickCancel":_vm.onClickCancel,"onClickSave":_vm.saveOptionBase,"isSaving":_vm.isSaving,"isSavingSuccess":_vm.isSavingSuccess,"isSavingError":_vm.isSavingError,"hideFooterButtons":_vm.isSavingError || _vm.isSavingSuccess,"showModal":_vm.showModal},on:{"closeModal":_vm.onClickCancel},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('table',{staticClass:"table is-fullwidth"},[_c('tbody',[_c('tr',[_c('td',[_vm._v("Name")]),_c('td',[_c('ui-base-input-field',{attrs:{"error":_vm.nameErrors,"required":true,"showLabel":false,"fieldType":"text"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.saveOptionName.apply(null, arguments)},"input":function($event){return _vm.$v.mOption.Name.$touch()},"blur":function($event){return _vm.$v.mOption.Name.$touch()}},model:{value:(_vm.mOption.Name),callback:function ($$v) {_vm.$set(_vm.mOption, "Name", $$v)},expression:"mOption.Name"}})],1)]),_c('tr',[_c('td',[_vm._v("Max amount")]),_c('td',[_c('div',{staticClass:"tabs is-toggle is-inline-flex is-small is-pulled-left"},[_c('ul',[_c('li',{class:{
                    'is-active': _vm.mOption.MaxAmount === 0,
                  }},[_c('a',{on:{"click":function($event){_vm.mOption.MaxAmount = 0}}},[_c('span',[_vm._v("Off")])])]),_c('li',{class:{
                    'is-active': _vm.mOption.MaxAmount > 0,
                  }},[_c('a',{on:{"click":function($event){_vm.mOption.MaxAmount = 1}}},[_c('span',[_vm._v("On")])])])])]),(_vm.mOption.MaxAmount > 0)?_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.mOption.MaxAmount),expression:"mOption.MaxAmount"}],staticClass:"input",attrs:{"type":"text"},domProps:{"value":(_vm.mOption.MaxAmount)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.mOption, "MaxAmount", $event.target.value)}}})]):_vm._e()])]),_c('tr',[_c('td',[_vm._v("Minimum duration")]),_c('td',[_c('div',{staticClass:"tabs is-toggle is-inline-flex is-small is-pulled-left"},[_c('ul',[_c('li',{class:{
                    'is-active': _vm.mOption.MinimumReservationDuration === 0,
                  }},[_c('a',{on:{"click":function($event){_vm.mOption.MinimumReservationDuration = 0}}},[_c('span',[_vm._v("Off")])])]),_c('li',{class:{
                    'is-active': _vm.mOption.MinimumReservationDuration > 0,
                  }},[_c('a',{on:{"click":function($event){_vm.mOption.MinimumReservationDuration = 60}}},[_c('span',[_vm._v("On")])])])])]),(_vm.mOption.MinimumReservationDuration > 0)?_c('div',{staticClass:"has-padding-left"},[_c('div',{staticClass:"select"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.mOption.MinimumReservationDuration),expression:"mOption.MinimumReservationDuration"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.mOption, "MinimumReservationDuration", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.hours),function(hour,index){return _c('option',{key:index,domProps:{"value":hour}},[_vm._v(" "+_vm._s(hour / 60)+" ")])}),0)])]):_vm._e()])]),_c('tr',[_c('td',[_vm._v("Maximum duration")]),_c('td',[_c('div',{staticClass:"tabs is-toggle is-inline-flex is-small is-pulled-left"},[_c('ul',[_c('li',{class:{
                    'is-active': _vm.mOption.MaximumReservationDuration === 0,
                  }},[_c('a',{on:{"click":function($event){_vm.mOption.MaximumReservationDuration = 0}}},[_c('span',[_vm._v("Off")])])]),_c('li',{class:{
                    'is-active': _vm.mOption.MaximumReservationDuration > 0,
                  }},[_c('a',{on:{"click":function($event){_vm.mOption.MaximumReservationDuration = 60}}},[_c('span',[_vm._v("On")])])])])]),(_vm.mOption.MaximumReservationDuration > 0)?_c('div',{staticClass:"has-padding-left"},[_c('div',{staticClass:"select"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.mOption.MaximumReservationDuration),expression:"mOption.MaximumReservationDuration"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.mOption, "MaximumReservationDuration", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.hours),function(hour,index){return _c('option',{key:index,domProps:{"value":hour}},[_vm._v(" "+_vm._s(hour / 60)+" ")])}),0)])]):_vm._e()])]),_c('tr',[_c('td',[_vm._v("Minimum participants")]),_c('td',[_c('div',{staticClass:"tabs is-toggle is-inline-flex is-small is-pulled-left"},[_c('ul',[_c('li',{class:{
                    'is-active': _vm.mOption.MinimumParticipants === 0,
                  }},[_c('a',{on:{"click":function($event){_vm.mOption.MinimumParticipants = 0}}},[_c('span',[_vm._v("Off")])])]),_c('li',{class:{
                    'is-active': _vm.mOption.MinimumParticipants > 0,
                  }},[_c('a',{on:{"click":function($event){_vm.mOption.MinimumParticipants = 1}}},[_c('span',[_vm._v("On")])])])])]),(_vm.mOption.MinimumParticipants > 0)?_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.mOption.MinimumParticipants),expression:"mOption.MinimumParticipants"}],staticClass:"input",attrs:{"type":"text"},domProps:{"value":(_vm.mOption.MinimumParticipants)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.mOption, "MinimumParticipants", $event.target.value)}}})]):_vm._e()])]),_c('tr',[_c('td',[_vm._v("Maximum participants")]),_c('td',[_c('div',{staticClass:"tabs is-toggle is-inline-flex is-small is-pulled-left"},[_c('ul',[_c('li',{class:{
                    'is-active': _vm.mOption.MaximumParticipants === 0,
                  }},[_c('a',{on:{"click":function($event){_vm.mOption.MaximumParticipants = 0}}},[_c('span',[_vm._v("Off")])])]),_c('li',{class:{
                    'is-active': _vm.mOption.MaximumParticipants > 0,
                  }},[_c('a',{on:{"click":function($event){_vm.mOption.MaximumParticipants = 1}}},[_c('span',[_vm._v("On")])])])])]),(_vm.mOption.MaximumParticipants > 0)?_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.mOption.MaximumParticipants),expression:"mOption.MaximumParticipants"}],staticClass:"input",attrs:{"type":"text"},domProps:{"value":(_vm.mOption.MaximumParticipants)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.mOption, "MaximumParticipants", $event.target.value)}}})]):_vm._e()])])])])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }